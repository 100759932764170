import React, { PropsWithChildren, ReactElement } from 'react'
import styled from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import Edge from './Edge'

const StyledContent = styled.div<{
  direction?: 'up' | 'down'
}>`
  position: relative;
  z-index: 1;

  && {
    margin: ${({ direction }) => (direction === 'up' ? '-2% 0 0 ' : '0 0 -2%')};
  }
`
const StyledWrapper = styled(Wrapper)`
  overflow: hidden;
`

export type WrapperWithJaggedEdgeProps = {
  className?: string
  edgeBackgroundColor?: string
  direction?: 'up' | 'down'
}

const WrapperWithJaggedEdge = ({
  className,
  edgeBackgroundColor,
  direction = 'down',
  children,
}: PropsWithChildren<WrapperWithJaggedEdgeProps>): ReactElement => (
  <div className={className}>
    {direction === 'up' && (
      <Edge backgroundColor={edgeBackgroundColor} direction="up" />
    )}

    <StyledWrapper background={COLOR.ACCENT.PRIMARY}>
      <StyledContent direction={direction}>{children}</StyledContent>
    </StyledWrapper>

    {direction !== 'up' && <Edge backgroundColor={edgeBackgroundColor} />}
  </div>
)

export default WrapperWithJaggedEdge
