import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { H, P, Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'

import { CHARITY_AMOUNT_RAISED } from 'config'
import { sentenceCase } from 'lib/formatting/text'
import CharitiesList from 'components/CharitiesList'

const StyledBanner = styled(Wrapper)`
  border-radius: ${GTR.S};
`

const Charity = (): ReactElement => (
  <Wrapper container>
    <StyledBanner
      background={COLOR.BACKGROUND.FOG}
      bordered
      centered
      padding={['XL', 'S', 'XL']}
    >
      <H tag="h2" size="L" decorative>
        {sentenceCase(CHARITY_AMOUNT_RAISED)} pledged to charity
      </H>

      <P maxWidthInColumns={5} margin={[0, 'auto', 'XL']}>
        Our generous customers have pledged {CHARITY_AMOUNT_RAISED} to our
        charity partners by leaving a gift in their will.
      </P>
      <CharitiesList withMoreCharitiesText />
    </StyledBanner>
  </Wrapper>
)

export default Charity
