import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Wrapper, Image } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { CLOUDINARY_ROOT_PATH } from '@farewill/ui/components/Image/constants'
import { screenMin } from '@farewill/ui/helpers/responsive'

import { CHARITY_LOGOS } from 'lib/charities/constants'

const DEFAULT_LOGO_HEIGHT = 36

const StyledCharitiesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: ${GTR.S};
  align-items: center;
  justify-content: center;
  color: ${COLOR.BLACK};

  & > * {
    margin: ${GTR.XS} ${GTR.S};
  }

  ${screenMin.l`
  justify-content: ${({ $spreadItems }) =>
    $spreadItems ? 'space-between' : 'center'};
  `}
`

const CharitiesList = ({ withMoreCharitiesText, withWrapper, spreadItems }) => {
  const content = (
    <StyledCharitiesWrapper $spreadItems={spreadItems}>
      {CHARITY_LOGOS.map(({ name, imagePath, height }) => (
        <Image
          key={name}
          alt={name}
          title={`${name} logo`}
          width={height || DEFAULT_LOGO_HEIGHT}
          height={height || DEFAULT_LOGO_HEIGHT}
          path={imagePath}
          formatPath={({ path, width }) =>
            `${CLOUDINARY_ROOT_PATH}/c_scale,f_auto,q_auto,h_${width}/e_trim/${path}.png`
          }
        />
      ))}
      {withMoreCharitiesText && <span>and hundreds more</span>}
    </StyledCharitiesWrapper>
  )

  if (!withWrapper) return content

  return (
    <Wrapper
      padding={['S', 0]}
      background={COLOR.BACKGROUND.FOG}
      bordered
      borderRadius="M"
    >
      {content}
    </Wrapper>
  )
}

CharitiesList.propTypes = {
  withMoreCharitiesText: PropTypes.bool,
  withWrapper: PropTypes.bool,
  spreadItems: PropTypes.bool,
}

CharitiesList.defaultProps = {
  withMoreCharitiesText: false,
  withWrapper: false,
  spreadItems: false,
}

export default CharitiesList
