import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { P, Grid, Wrapper, Image, H } from '@farewill/ui'
import { GTR, BORDER_RADIUS, COLOR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'

const StyledFunTeamPhoto = styled(Image)`
  width: 100%;
  border-radius: ${BORDER_RADIUS.S};
  margin: ${GTR.M} 0;

  ${screenMin.m`
    margin: ${GTR.XL} 0;
  `};
`

const Team = (): ReactElement => (
  <Wrapper background={COLOR.BACKGROUND.FOG}>
    <Wrapper container>
      <Grid>
        <Grid.Item spanFromM={9} spanFromL={7}>
          <H
            margin={[0, 0, 'S']}
            marginFromM={['L', 0, 'S']}
            size="L"
            decorative
          >
            The team
          </H>
          <P>
            We’re solicitors, product managers and salespeople; designers,
            developers and data analysts, all with the shared goal of making
            things simpler and more accessible for our customers.
          </P>
        </Grid.Item>
      </Grid>
      <Grid
        margin={[0, 0, 'M']}
        marginFromM={[0, 0, 'L']}
        marginFromL={[0, 0, 'M']}
      >
        <Grid.Item>
          <StyledFunTeamPhoto
            path="photos/team-02-20-fun"
            ext="jpg"
            width={590}
            widthFromM={870}
            widthFromL={1200}
            alt="team photo"
          />
        </Grid.Item>
      </Grid>

      <Grid margin={['M', 0, 0]} paddingFromM={['M', 0, 'XL']}>
        <Grid.Item spanFromM={9} spanFromL={4}>
          <H tag="h2" size="L" decorative>
            Diverse teams lead to better solutions
          </H>
        </Grid.Item>
        <Grid.Item spanFromM={10} startColumnFromL={6} spanFromL={7}>
          <P>
            Death is something that will affect everybody at some point in their
            lives, regardless of race, gender, background, religion, disability
            or sexuality. We believe that the best way to build products and
            services that truly help people all over the UK is to build the most
            diverse team possible. So if you’re from an under-represented
            background and want to make your voice heard, please apply for one
            of our latest roles below.
          </P>
        </Grid.Item>
      </Grid>
    </Wrapper>
  </Wrapper>
)

export default Team
