import styled, { css } from 'styled-components'
import { ButtonBack, ButtonNext } from 'pure-react-carousel'
import { COLOR } from '@farewill/ui/tokens'
import { screenMax, screenMin } from '@farewill/ui/helpers/responsive'

const BUTTON_SIZE = 32

const StyledArrow = css`
  width: ${BUTTON_SIZE}px;
  height: ${BUTTON_SIZE}px;
  color: ${COLOR.BLACK};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(40% - ${BUTTON_SIZE / 2}px);

  &:disabled {
    color: ${COLOR.GREY.MEDIUM};
  }

  ${screenMax.m`
    display: none;
  `}

  ${screenMin.l`
    top: calc(50% - ${BUTTON_SIZE}px);
  `}
`

export const StyledBackButton = styled(ButtonBack)`
  ${StyledArrow}
  left: 0;

  ${screenMin.m`
    left: -${BUTTON_SIZE}px;
  `}

  ${screenMin.l`
    left: -${BUTTON_SIZE * 1.5}px;
  `}
`

export const StyledNextButton = styled(ButtonNext)`
  ${StyledArrow}
  right: 0;

  ${screenMin.m`
    right: -${BUTTON_SIZE}px;
  `}

  ${screenMin.l`
    right: -${BUTTON_SIZE * 1.5}px;
  `}
`
