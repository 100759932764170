import React from 'react'
import styled from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import Edge from './Edge'

const StyledContent = styled.div<{
  direction: WrapperWithEdgeProps['direction']
}>`
  position: relative;
  z-index: 1;

  && {
    margin: ${({ direction }) => (direction === 'up' ? '-2% 0 0 ' : '0 0 -2%')};
  }
`

export type WrapperWithEdgeProps = {
  className?: string
  edgeBackgroundColor?: string
  lightAccent?: boolean
  creamAccent?: boolean
  direction: 'up' | 'down' | 'vertical'
  flipEdge?: boolean
  children: React.ReactNode
  padding?: string | number | (string | number)[]
  paddingFromL?: string | number | (string | number)[]
  thickEdge?: boolean
}

const WrapperWithEdge = ({
  className = '',
  edgeBackgroundColor,
  direction,
  flipEdge = false,
  lightAccent = false,
  creamAccent = false,
  children,
  padding,
  paddingFromL,
  thickEdge = false,
}: WrapperWithEdgeProps): React.ReactElement => {
  let background: string = COLOR.ACCENT.PRIMARY

  if (lightAccent) {
    background = COLOR.ACCENT.PRIMARY_60
  } else if (creamAccent) {
    background = COLOR.ACCENT.PRIMARY_20
  }

  return (
    <div className={className}>
      {(direction === 'up' || direction === 'vertical') && (
        <Edge
          backgroundColor={edgeBackgroundColor}
          lightAccent={lightAccent}
          creamAccent={creamAccent}
          direction="up"
          flipEdge={flipEdge || direction === 'vertical'}
          thickEdge={thickEdge}
        />
      )}

      <Wrapper
        background={background}
        padding={padding}
        paddingFromL={paddingFromL || padding}
      >
        <StyledContent direction={direction}>{children}</StyledContent>
      </Wrapper>

      {(direction === 'down' || direction === 'vertical') && (
        <Edge
          backgroundColor={edgeBackgroundColor}
          lightAccent={lightAccent}
          creamAccent={creamAccent}
          flipEdge={direction === 'vertical'}
          thickEdge={thickEdge}
        />
      )}
    </div>
  )
}

export default WrapperWithEdge
