export const CHARITIES_REQUEST_CALLBACK_URL =
  'https://form.typeform.com/to/t4vEpgcp'

export const CHARITY_LOGOS = [
  {
    name: 'Macmillan Cancer Support',
    imagePath: 'home-page-charity-logos/macmillan-large',
    height: 30,
  },
  {
    name: 'Marie Curie',
    imagePath: 'home-page-charity-logos/marie-curie',
    height: 42,
  },
  {
    name: 'Alzheimer’s Society',
    imagePath: 'home-page-charity-logos/alzheimers-society',
  },
  {
    name: 'British Heart Foundation',
    imagePath: 'home-page-charity-logos/british-heart-foundation',
  },
  {
    name: 'Royal British Legion',
    imagePath: 'home-page-charity-logos/royal-british-legion',
    height: 38,
  },
  {
    name: 'Age UK',
    imagePath: 'home-page-charity-logos/age-uk',
    height: 38,
  },
  {
    name: 'Royal National Lifeboat Institution',
    imagePath: 'home-page-charity-logos/rnli',
  },
  {
    name: 'Cancer Research UK',
    imagePath: 'home-page-charity-logos/cruk-large',
  },
]
