import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { BackgroundImageWrapper } from '@farewill/ui'
import { COLOR, BREAKPOINT } from '@farewill/ui/tokens'

const StyledEdge = styled(BackgroundImageWrapper)<{
  direction?: 'up' | 'down'
}>`
  position: relative;
  width: 100%;
  padding-bottom: 5.37%;

  ${({ direction }) =>
    direction === 'up' &&
    `
    padding-bottom: 6%;
    transform: rotate(180deg);

    &:before {
      content: '';
      width: 100%;
      height: 5px;
      background: ${COLOR.ACCENT.PRIMARY};
      position: absolute;
      top: -3px;
      left: 0;
      right: 0;
    }
  `}
`

type EdgeProps = {
  backgroundColor?: string
  direction?: 'up' | 'down'
}

const Edge = ({ backgroundColor, direction }: EdgeProps): ReactElement => (
  <StyledEdge
    imagePath="textures/jagged-edge"
    imageWidth={BREAKPOINT.S}
    imageWidthFromM={BREAKPOINT.M}
    imageWidthFromL={BREAKPOINT.L}
    imageWidthFromXL={2000}
    backgroundColor={backgroundColor}
    backgroundPosition="center"
    direction={direction}
    cover
  />
)

export default Edge
