import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { H, P, Wrapper } from '@farewill/ui'
import { screenMax } from '@farewill/ui/helpers/responsive'

import ValuesCarousel from './ValuesCarousel/Index'

const StyledWrapper = styled(Wrapper)`
  ${screenMax.m`
    overflow: hidden;
  `}
`

const Values = (): ReactElement => (
  <>
    <StyledWrapper container>
      <Wrapper centeredFromL padding={['M', 0]} paddingFromL={0}>
        <H tag="h2" size="L" decorative>
          Our values
        </H>
        <P size="L" maxWidthInColumns={7} margin={[0, 'auto', 'XL']}>
          Our values are what makes Farewill, Farewill. They’re the skills and
          behaviours we look for in ourselves and each other.
        </P>
      </Wrapper>
      <ValuesCarousel />
    </StyledWrapper>
  </>
)

export default Values
