import React, { ReactElement } from 'react'
import styled, { css } from 'styled-components'
import { Wrapper, Image, BackgroundImageWrapper } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { screenMax, screenMin } from '@farewill/ui/helpers/responsive'

import { SLIDES_BOTTOM, SLIDES_TOP, Slide } from './constants'

const YEAR_WIDTH = 72

const StyledWrapper = styled(Wrapper)`
  height: 100%;
  display: grid;

  grid-template-rows: 140px ${YEAR_WIDTH}px max-content;
  margin-bottom: ${GTR.M};
  gap: ${GTR.S};

  ${screenMin.l`
    grid-template-rows: 120px 160px ${YEAR_WIDTH}px max-content;
    margin-bottom: 0;
  `}
`

const StyledYear = styled(BackgroundImageWrapper)<{
  $showYear: boolean
  $position: 'top' | 'bottom'
}>`
  font-weight: ${FONT.WEIGHT.BOLD};
  color: ${COLOR.GREY.DARK};
  height: ${YEAR_WIDTH}px;
  width: ${YEAR_WIDTH}px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;

  ${({ $showYear }) =>
    !$showYear &&
    css`
      ${screenMin.l`
      opacity: 0;
    `}
    `}
`

const StyledTimeline = styled(Wrapper)<{
  $showYear: boolean
}>`
  position: absolute;
  top: ${YEAR_WIDTH / 2 - 4}px;
  left: 46px;
  right: -43px;
  z-index: 0;
  height: 10px;
  background-image: repeating-linear-gradient(
      to right,
      transparent,
      transparent 10px,
      white 10px,
      white 40px
    ),
    url('https://res.cloudinary.com/dwr27vxv7/image/upload/v1570616064/textures/yellow-dot.png');
  background-repeat: repeat-x;
  background-size: 40px 10px, 10px 10px;

  ${screenMax.s`
    left: ${YEAR_WIDTH}px;
    right: 0;
    width: 130px;
    margin: auto;
  `}

  ${screenMax.m`
  left: ${YEAR_WIDTH}px;
  right: 0;
  width: 230px;
  margin: auto;
  `}

  ${screenMax.l`
  left: ${YEAR_WIDTH}px;
  right: 0;
  width: 200px;
  margin: auto;
  `}
`

const StyledDescription = styled(Wrapper)<{
  $position: 'top' | 'bottom'
}>`
  border-left: 1px solid ${COLOR.GREY.DARK};
  padding-left: ${GTR.S};
  margin-left: ${YEAR_WIDTH / 2}px;
  padding-top: ${GTR.XXS};

  p {
    font-size: ${FONT.SIZE.S};
    margin-bottom: ${GTR.XS};
  }

  ${({ $position }) =>
    $position === 'bottom' &&
    `
      padding-bottom: ${GTR.S};
      margin-bototm: ${GTR.M};
  `}
  ${({ $position }) => $position === 'top' && 'flex-grow: 1;'}

  ${screenMax.m`
    p {
      padding-right: ${GTR.M};
    }
  `}
`

const StyledImageWrapper = styled(Wrapper)<{
  $position: 'top' | 'bottom'
}>`
  height: 120px;
  margin: 0 auto;
  text-align: center;

  img {
    max-height: 100%;

    ${({ $position }) => $position === 'bottom' && `transition-delay: 0.4s;`}
  }

  ${screenMax.m`
    display: none;
  `}
`

const StyledYearWithTimelineWrapper = styled(Wrapper)`
  position: relative;
`

type CarouselSlideProps = {
  index: number
}

const SingleSlide = ({
  slideTop,
  slideBottom,
}: {
  slideTop: Slide
  slideBottom: Slide
}) => (
  <StyledWrapper>
    <StyledImageWrapper $position="top">
      {slideTop.illustration && (
        <Image path={slideTop.illustration} width={200} loading="auto" />
      )}
    </StyledImageWrapper>

    {slideTop.description && (
      <StyledDescription $position="top">
        {slideTop.description}
      </StyledDescription>
    )}

    <StyledYearWithTimelineWrapper>
      <StyledYear
        $position="top"
        $showYear={slideTop.showYear}
        imagePath="textures/blob-1-yellow"
        imageWidth={72}
      >
        {slideTop.year}
      </StyledYear>
      <StyledTimeline $showYear={slideTop.showYear} />
    </StyledYearWithTimelineWrapper>
    <div>
      {slideBottom.description && (
        <StyledDescription $position="bottom">
          {slideBottom.description}
        </StyledDescription>
      )}

      <StyledImageWrapper $position="bottom">
        {slideBottom.illustration && (
          <Image path={slideBottom.illustration} width={200} loading="auto" />
        )}
      </StyledImageWrapper>
    </div>
  </StyledWrapper>
)

const CarouselSlide = ({ index }: CarouselSlideProps): ReactElement => (
  <SingleSlide
    slideTop={SLIDES_TOP[index]}
    slideBottom={SLIDES_BOTTOM[index]}
  />
)

export default CarouselSlide
