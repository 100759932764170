import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { GTR } from '@farewill/ui/tokens'

import ValuesCard from 'components/ValuesCard'
import { VALUES } from 'components/ValuesCard/data'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  ${screenMin.l`
    display: block;
  `}
`

const StyledInnerWrapper = styled(Wrapper)`
  padding-right: ${GTR.S};
  padding-left: ${GTR.S};
  height: 100%;

  ${screenMin.l`
  padding-right: ${GTR.XS};
  padding-left: ${GTR.XS};
  `}
`

type CarouselSlideProps = {
  slide: (typeof VALUES)[number]
}

const CarouselSlide = ({ slide }: CarouselSlideProps): ReactElement => (
  <StyledWrapper>
    <StyledInnerWrapper>
      <ValuesCard
        title={slide.title}
        description={slide.description}
        illustration={slide.illustration}
      />
    </StyledInnerWrapper>
  </StyledWrapper>
)

export default CarouselSlide
