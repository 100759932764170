import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { BREAKPOINT, GTR } from '@farewill/ui/tokens'
import { screenMax, screenMin } from '@farewill/ui/helpers/responsive'
import { ChevronLeftIcon, ChevronRightIcon, Wrapper } from '@farewill/ui'

import useWindowSize from 'lib/ui/useWindowSize'
import { VALUES } from 'components/ValuesCard/data'

import CarouselSlide from './CarouselSlide'
import {
  StyledBackButton,
  StyledNextButton,
} from '../components/CarouselButtons'
import { Dots } from '../components/CarouselDots'

const BUTTON_SIZE = 32

const StyledCarouselWrapper = styled(Wrapper)`
  position: relative;
  max-width: calc(100% - ${BUTTON_SIZE * 2}px);
  margin: 0 auto;

  .carousel__inner-slide {
    height: 100%;
    width: 100%;

    left: 10px;
    top: 10px;
  }

  img {
    transition: all 0.4s ease-in-out;
    transition-delay: 0.2s;
    opacity: 1;
  }

  .carousel__slide--hidden {
    img {
      transform: scale(0.7);
      opacity: 0;
    }
  }

  ${screenMax.m`
  margin-left: -${GTR.L};
  margin-right: -${GTR.L};
  max-width: none;

  .carousel__slider {
    padding-left: ${GTR.S};
    padding-right: ${GTR.S};
  }

  .carousel__slide--visible + .carousel__slide--visible {
    opacity: 0.6;

    img {
      transform: scale(0.7);
      opacity: 0;
    }
  }
  `}

  ${screenMin.xl`
  max-width: 100%;
  `}
`

const Carousel = (): ReactElement => {
  const windowSize = useWindowSize()
  const [visibleSlides, setVisibleSlides] = useState(3)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (isMobile && visibleSlides !== 1) {
      setVisibleSlides(1.2)
    } else if (!isMobile && visibleSlides !== 3) {
      setVisibleSlides(3)
    }
  }, [windowSize, isMobile])

  useEffect(() => {
    setIsMobile((windowSize.width || 0) < BREAKPOINT.M)
  }, [windowSize])

  return (
    <StyledCarouselWrapper padding={0} paddingFromL={['XL', 0]}>
      <CarouselProvider
        totalSlides={VALUES.length}
        visibleSlides={visibleSlides}
        naturalSlideWidth={280}
        naturalSlideHeight={280}
        isIntrinsicHeight
        lockOnWindowScroll
      >
        <Slider preventVerticalScrollOnTouch>
          {VALUES.map((slide, i) => (
            <Slide style={{ width: '95%' }} index={i} key={slide.id}>
              <CarouselSlide slide={slide} />
            </Slide>
          ))}
        </Slider>
        <Dots isMobile={isMobile} />
        <StyledBackButton>
          <ChevronLeftIcon size="M" />
        </StyledBackButton>
        <StyledNextButton>
          <ChevronRightIcon size="M" />
        </StyledNextButton>
      </CarouselProvider>
    </StyledCarouselWrapper>
  )
}

export default Carousel
