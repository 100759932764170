import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Wrapper, Grid, H, BulletedList, TickIcon } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import { BENEFITS } from './constants'

const StyledGrid = styled(Grid)`
  width: 100%;
  align-items: flex-start;
`

const Benefits = (): ReactElement => (
  <Wrapper>
    <Wrapper container>
      <StyledGrid
        margin={[0, 0, 'L']}
        marginFromL={['XXL', 0]}
        padding={0}
        paddingFromL={['XL', 0, 0]}
      >
        <Grid.Item
          spanFromM={10}
          startColumnFromM={2}
          spanFromL={8}
          startColumnFromL={3}
          centeredFromM
        >
          <H size="L" decorative>
            What we offer
          </H>
        </Grid.Item>
      </StyledGrid>
      <BulletedList
        icon={
          <TickIcon background={COLOR.ACCENT.PRIMARY} color={COLOR.BLACK} />
        }
        headingSize="XS"
      >
        {BENEFITS.map(({ name, description }, j) => (
          <Grid.Item
            as={BulletedList.Item}
            title={name}
            key={name}
            startColumnFromM={j < BENEFITS.length / 2 ? 1 : 7}
            startColumnFromL={j < BENEFITS.length / 2 ? 3 : 7}
            spanFromM={6}
            spanFromL={4}
          >
            {description}
          </Grid.Item>
        ))}
      </BulletedList>
    </Wrapper>
  </Wrapper>
)

export default Benefits
