import React, { ReactElement } from 'react'
import { BREAKPOINT } from '@farewill/ui/tokens'
import { CLOUDINARY_ROOT_PATH } from '@farewill/ui/components/Image/constants'

type SourceProps = { path: string; width: number; ext: string; media: string }

const getVideoSrc = (
  path: SourceProps['path'],
  width: SourceProps['width'],
  ext = 'mp4'
) =>
  `${CLOUDINARY_ROOT_PATH.replace(
    '/image/',
    '/video/'
  )}/c_scale,w_${width}/${path}.${ext}`

const WIDTH_L = 556
const WIDTH_M = 890

const Source = ({ path, width, ext, media }: SourceProps): ReactElement => (
  <source
    src={getVideoSrc(path, width, ext)}
    media={media}
    type={`video/${ext}`}
  />
)

const RetinaSource = ({
  path,
  width,
  ext,
  media,
}: SourceProps): ReactElement => (
  <>
    <Source
      path={path}
      width={width * 2}
      ext={ext}
      media={`${media && `${media} and `}(min-device-pixel-ratio: 2)`}
    />
    <Source path={path} width={width} ext={ext} media={media} />
  </>
)

const Video = ({
  path,
  hideOnM,
}: {
  path: string
  hideOnM?: boolean
}): ReactElement => (
  <video
    muted
    loop
    autoPlay
    poster={getVideoSrc(path, WIDTH_L * 2, 'jpg')}
    playsInline
  >
    <RetinaSource
      path={path}
      width={WIDTH_L}
      ext="webm"
      media={`(min-width: ${BREAKPOINT.M}px)`}
    />
    <RetinaSource
      path={path}
      width={WIDTH_L}
      ext="mp4"
      media={`(min-width: ${BREAKPOINT.M}px)`}
    />
    {!hideOnM && (
      <>
        <RetinaSource
          path={path}
          width={WIDTH_M}
          ext="webm"
          media={`(min-width: ${BREAKPOINT.S}px)`}
        />
        <RetinaSource
          path={path}
          width={WIDTH_M}
          ext="mp4"
          media={`(min-width: ${BREAKPOINT.S}px)`}
        />
      </>
    )}
  </video>
)

export default Video
