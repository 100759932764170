import React, { ReactElement } from 'react'
import { Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'
import { CLOUDINARY_ROOT_PATH } from '@farewill/ui/components/Image/constants'

import DefaultLayout from 'layouts/DefaultLayout'
import { SHARE_IMAGE_DIMENSIONS } from 'lib/sharing/constants'
import Charity from 'components/Charity'

import Hero from './Hero'
import Team from './Team'
import JobsList from './JobsList'
import HowWeWork from './HowWeWork'
import Benefits from './Benefits'
import Mission from './Mission'
import Values from './Values'
import TimelineCarousel from './TimelineCarousel'

const JOBS_LIST_ANCHOR_ELEMENT_ID = 'job-list'

const CareersPage = (): ReactElement => (
  <DefaultLayout
    title="Work with Us | Jobs & Careers"
    description="Join us in changing how the world thinks and plans for death."
    headerColor={COLOR.ACCENT.PRIMARY}
    shareImage={`${CLOUDINARY_ROOT_PATH}/c_fill,q_auto,w_${SHARE_IMAGE_DIMENSIONS.WIDTH},h_${SHARE_IMAGE_DIMENSIONS.HEIGHT}/photos/photo-meeting-on-sofa.jpg`}
  >
    <Wrapper background={COLOR.WHITE}>
      <Hero />
      <Mission />
      <TimelineCarousel />
      <Charity />
      <Team />
      <HowWeWork />
      <Benefits />
      <Values />
      <JobsList anchorElementId={JOBS_LIST_ANCHOR_ELEMENT_ID} />
    </Wrapper>
  </DefaultLayout>
)

export default CareersPage
