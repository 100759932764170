import React from 'react'
import styled from 'styled-components'
import { BackgroundImageWrapper } from '@farewill/ui'
import { COLOR, BREAKPOINT } from '@farewill/ui/tokens'

const StyledEdge = styled(BackgroundImageWrapper)`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: ${({ $thickEdge }) => ($thickEdge ? 13 : 6)}%;
  color: ${({ $lightAccent, $creamAccent }) => {
    if ($lightAccent) {
      return COLOR.ACCENT.PRIMARY_60
    }
    if ($creamAccent) {
      return COLOR.ACCENT.PRIMARY_20
    }
    return COLOR.ACCENT.PRIMARY
  }};
  margin: -5px 0 0;

  ${({ direction }) =>
    direction === 'up' &&
    `
    transform: rotate(180deg);
    margin: -1% 0 -1px;
  `}

  ${({ direction, $flipEdge }) =>
    direction === 'up' &&
    $flipEdge &&
    `
    transform: rotate(180deg) scale(-1, 1);
  `}

${({ direction, $flipEdge }) =>
    direction === 'down' &&
    $flipEdge &&
    `
    transform: scale(-1, 1);
  `}

  svg {
    position: absolute;
  }
`

type EdgeProps = {
  backgroundColor?: string
  direction?: 'up' | 'down'
  thickEdge?: boolean
  lightAccent?: boolean
  creamAccent?: boolean
  flipEdge?: boolean
}

const Edge = ({
  lightAccent = false,
  creamAccent = false,
  backgroundColor,
  direction = 'down',
  thickEdge = false,
  flipEdge = false,
}: EdgeProps): React.ReactElement => {
  let imagePath = ''

  if (thickEdge) {
    imagePath = 'edge-2'
  } else {
    imagePath = 'edge-1'
  }

  if (lightAccent) {
    imagePath += '-light'
  } else if (creamAccent) {
    imagePath += '-cream'
  }

  return (
    <StyledEdge
      alt="" // Let screen readers know this is a decorative image
      imagePath={`textures/${imagePath}`}
      imageWidth={BREAKPOINT.S}
      imageWidthFromS={BREAKPOINT.M}
      imageWidthFromM={BREAKPOINT.L}
      backgroundColor={backgroundColor}
      backgroundPosition="center"
      direction={direction}
      $thickEdge={thickEdge}
      $lightAccent={lightAccent}
      $creamAccent={creamAccent}
      $flipEdge={flipEdge}
      cover
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2592 156">
        <path d="M2592 0v19s-734 105-1339 105S0 18 0 18V0h2592z" />
      </svg>
    </StyledEdge>
  )
}

export default Edge
