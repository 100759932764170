import React, { ReactNode } from 'react'
import { P } from '@farewill/ui'

export type Slide =
  | {
      id: string
      year: number
      showYear?: boolean
      description?: ReactNode | string
      illustration?: string
    }
  | Record<string, never>

export const SLIDES_TOP = [
  {
    id: 'will-writing-launch',
    year: 2016,
    showYear: true,
    description: (
      <P>
        We launched our online <strong>will writing</strong> service to take the
        stress out of making a will
      </P>
    ),
    illustration: 'e_trim/illustrations/blob-signing-will-thumbs-up',
  },
  {
    id: 'probate-services-launch',
    year: 2019,
    showYear: true,
    description: (
      <P>
        We teamed up with specialist legal partners to launch our{' '}
        <strong>probate services</strong>
      </P>
    ),
    illustration: 'e_trim/illustrations/assets-with-children',
  },
  {
    id: 'cremation-launch',
    year: 2020,
    showYear: true,
    description: (
      <P>
        We launched our <strong>cremation</strong> service to challenge the
        rising cost of funerals in the UK
      </P>
    ),
    illustration: 'e_trim/illustrations/ashes-and-family',
  },
  {
    id: 'nhs-wills',
    year: 2020,
    showYear: false,
    description: (
      <P>
        We provided over 15,000 free wills to NHS workers in just 4 months, at
        the peak of the pandemic
      </P>
    ),
  },
  {
    id: 'rocket-list',
    year: 2021,
    showYear: true,
    description: (
      <P>
        We featured on Rocket List’s Top 20 High growth Startups to work for
      </P>
    ),
    illustration: 'e_trim/illustrations/trophy',
  },
  {
    id: 'crowdfunding',
    year: 2022,
    showYear: true,
    description: (
      <P>
        We invited our customers to own a share of Farewill too, raising over
        £2.5m in crowdfunding
      </P>
    ),
    illustration: 'e_trim/illustrations/calculator-helper-with-turtle',
  },
  {
    id: 'charity',
    year: 2023,
    showYear: true,
    description: (
      <P>
        We expanded our charity partnerships to include Oxfam, Age UK, NSPCC,
        Unicef and Mencap, among others
      </P>
    ),
  },
  {
    id: 'funeral-plans',
    year: 2024,
    showYear: true,
    description: (
      <P>
        We launched our <strong>funeral plan</strong> which is regulated by the
        Financial Conduct Authority (FCA).
      </P>
    ),
    illustration: 'e_trim/illustrations/lady_with_plan',
  },
] as Slide[]

export const SLIDES_BOTTOM = [
  {},
  {
    id: 'will-writing-firm-of-the-year',
    year: 2019,
    showYear: false,
    description: (
      <>
        <P>National Will Writing Firm of the Year</P>
        <P>Legal Innovation Awards: Outstanding Innovator</P>
      </>
    ),
    illustration: 'e_trim/illustrations/trophies',
  },
  {
    id: 'probate-services-launch',
    year: 2019,
    showYear: false,
    description: (
      <>
        <P>National Will Writing Firm of the Year</P>
        <P>Probate Provider of the Year</P>
      </>
    ),
  },
  {
    id: 'europas-social-innovation',
    year: 2020,
    showYear: false,
    description: (
      <>
        <P>The Europas: Hottest Social Innovation</P>
        <P>Modern Law Awards: Client Care Initiative of the Year</P>
        <P> Fintech Awards: Employer of the year</P>
      </>
    ),
  },
  {
    id: '2021-awards',
    year: 2021,
    showYear: false,
    description: (
      <>
        <P>National Will Writing Firm of the Year</P>
        <P>Probate Provider of the Year</P>
        <P>Low-cost funeral provider of the year</P>
        <P>Best funeral information provider</P>
      </>
    ),
  },
  {
    id: '2022-national-will-writing',
    year: 2022,
    showYear: false,
    description: (
      <>
        <P>National Will Writing Firm of the Year</P>
        <P>Low-cost funeral provider of the year</P>
        <P>Best Direct Cremation Provider</P>
      </>
    ),
  },
  {
    id: 'award',
    year: 2023,
    showYear: false,
    illustration: 'e_trim/illustrations/trophy',
  },
  {
    id: '1billion',
    year: 2024,
    showYear: false,
    description: (
      <P>
        We reached our £1billion target in pledged income for our charity
        partners.
      </P>
    ),
  },
] as Slide[]
