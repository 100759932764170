import React, { ReactElement } from 'react'
import styled from 'styled-components'
import {
  Grid,
  Image,
  BackgroundImageWrapper,
  H,
  P,
  Wrapper,
} from '@farewill/ui'
import { BORDER_RADIUS, COLOR } from '@farewill/ui/tokens'
import { screenMin, screenMax, screen } from '@farewill/ui/helpers/responsive'

import WrapperWithJaggedEdge from 'components/WrapperWithJaggedEdge'
import AspectRatioWrapper from 'components/AspectRatioWrapper'

import Video from './Video'

const ASPECT_RATIO_PORTRAIT = 2 / 3
const ASPECT_RATIO_LANDSCAPE = 3 / 2

const StyledWrapper = styled(WrapperWithJaggedEdge)`
  position: relative;
`
const StyledIllustrationWrapper = styled(Grid.Item)`
  align-self: center;
`

const StyledTexture = styled(Image)`
  position: absolute;
  right: 0;
  bottom: 20%;

  ${screenMax.m`
    display: none;
  `}
`

const StyledGridItem = styled(Grid.Item)`
  background: ${COLOR.BACKGROUND.SMOKE};
  border: 4px solid ${COLOR.WHITE};
  border-radius: ${BORDER_RADIUS.S};
  position: relative;

  ${screenMin.l`
    border: 8px solid ${COLOR.WHITE};
    border-radius: 8px;
  `}

  ${({ hideOnS }) =>
    hideOnS &&
    screenMax.s`
    display: none;
  `}

  ${({ hideOnM }) =>
    hideOnM &&
    screen.m`
    display: none;
  `}
`

const HowWeWork = (): ReactElement => (
  <StyledWrapper direction="up" edgeBackgroundColor={COLOR.BACKGROUND.FOG}>
    <StyledTexture path="textures/pattern-1" width={270} />

    <Grid container>
      <StyledIllustrationWrapper
        span={10}
        startColumn={2}
        startColumnFromM={0}
        spanFromM={9}
        spanFromL={6}
        startColumnFromL={7}
        spanRowsFromL={2}
      >
        <Wrapper centered>
          <Image
            path="e_trim/illustrations/blob-with-laptop-and-cat"
            width={250}
            widthFromM={320}
            widthFromL={500}
            stretch
          />
        </Wrapper>
      </StyledIllustrationWrapper>

      <Grid.Item spanFromM={10} spanFromL={6}>
        <H tag="h2" size="L" decorative>
          How we work
        </H>
        <P size="L" color={COLOR.BLACK}>
          Whatever your role here, you’ll have the chance to contribute to the
          direction and success of the business. From weekly all-hands meetings
          to quarterly planning sessions, you can share your ideas, collaborate
          with other teams and help us change the way the world deals with
          death.
        </P>
      </Grid.Item>
    </Grid>

    <Grid container containerPaddingTop={0}>
      <StyledGridItem
        role="figure"
        spanFromL={6}
        aria-label="Our probate solicitor June and Product Manager James meeting on the sofa"
      >
        <AspectRatioWrapper ratio={ASPECT_RATIO_LANDSCAPE}>
          <BackgroundImageWrapper
            imagePath="photos/photo-meeting-on-sofa"
            imageWidth={580}
            imageWidthFromM={890}
            imageWidthFromL={556}
            cover
          />
        </AspectRatioWrapper>
      </StyledGridItem>
      <StyledGridItem
        role="figure"
        hideOnS
        hideOnM
        spanFromL={6}
        aria-label="Fortnightly Tech Guild meeting at the big table"
      >
        <AspectRatioWrapper ratio={ASPECT_RATIO_LANDSCAPE}>
          <Video path="photos/timelapse-tech-guild-long" hideOnM />
        </AspectRatioWrapper>
      </StyledGridItem>

      <StyledGridItem
        role="figure"
        hideOnS
        hideOnM
        spanFromM={6}
        spanFromL={4}
        aria-label="A wall in our new office"
      >
        <AspectRatioWrapper ratio={ASPECT_RATIO_PORTRAIT}>
          <BackgroundImageWrapper
            imagePath="photos/photo-wall-calendar"
            imageWidth={320}
            imageWidthFromL={365}
            cover
          />
        </AspectRatioWrapper>
      </StyledGridItem>
      <StyledGridItem
        role="figure"
        hideOnS
        spanFromM={6}
        spanFromL={4}
        aria-label="Our bookshelves"
      >
        <AspectRatioWrapper ratio={ASPECT_RATIO_PORTRAIT}>
          <BackgroundImageWrapper
            imagePath="photos/photo-bookshelf"
            imageWidth={320}
            imageWidthFromL={365}
            cover
          />
        </AspectRatioWrapper>
      </StyledGridItem>
      <StyledGridItem
        role="figure"
        hideOnS
        spanFromM={6}
        spanFromL={4}
        aria-label="Design Guild meeting in the Boardroom"
      >
        <AspectRatioWrapper ratio={ASPECT_RATIO_PORTRAIT}>
          <BackgroundImageWrapper
            imagePath="photos/photo-meeting-in-boardroom"
            imageWidth={320}
            imageWidthFromL={365}
            cover
          />
        </AspectRatioWrapper>
      </StyledGridItem>

      <StyledGridItem
        role="figure"
        hideOnS
        spanFromL={6}
        aria-label="Serving Cuban lunch before Passion Talks"
      >
        <AspectRatioWrapper ratio={ASPECT_RATIO_LANDSCAPE}>
          <Video path="photos/timelapse-lunch-long" />
        </AspectRatioWrapper>
      </StyledGridItem>
      <StyledGridItem
        role="figure"
        hideOnS
        hideOnM
        spanFromL={6}
        aria-label="Effigy of our CEO, Dan Garrett"
      >
        <AspectRatioWrapper ratio={ASPECT_RATIO_LANDSCAPE}>
          <BackgroundImageWrapper
            imagePath="photos/photo-concrete-head-and-plants"
            imageWidth={556}
            cover
          />
        </AspectRatioWrapper>
      </StyledGridItem>
    </Grid>
  </StyledWrapper>
)

export default HowWeWork
