import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Grid, Image, H, P } from '@farewill/ui'
import { screenMax, screenMin } from '@farewill/ui/helpers/responsive'
import { FONT, GTR } from '@farewill/ui/tokens'

const StyledGrid = styled(Grid)`
  position: relative;

  ${screenMin.l`
    padding-bottom: calc(2 * ${GTR.XL});
    `}
`

const StyledP = styled(P)`
  ${screenMax.m`
    font-size: ${FONT.SIZE.M};
  `}
`

const Mission = (): ReactElement => (
  <StyledGrid container>
    <Grid.Item centeredFromL>
      <H
        tag="h2"
        size="L"
        decorative
        marginFromL={[0, 'auto', 'L']}
        maxWidth={800}
      >
        Our mission: to change the way the world deals with death
      </H>
    </Grid.Item>
    <Grid.Item centered startRow={1} startRowFromL={2} spanFromL={6}>
      <Image
        path="illustrations/cropped/blob-with-family-and-yellow-bg"
        width={250}
        widthFromL={400}
        widthFromXL={450}
        stretch
      />
    </Grid.Item>
    <Grid.Item spanFromL={6}>
      <StyledP size="L">
        All of us are going to die. It’s a fundamental, tragic, romantic part of
        what it is to be human. But the services that should be supporting us
        haven’t changed in decades.
      </StyledP>
      <StyledP size="L">
        From writing your will to planning a funeral, the way we deal with death
        is formal, complicated and expensive. Instead of feeling supported,
        people feel lost.
      </StyledP>
      <StyledP size="L" marginFromL={[0, 0, 'XL']}>
        But it doesn’t need to be this way. We started Farewill to make the
        experience warmer, brighter and friendlier.
      </StyledP>
    </Grid.Item>
  </StyledGrid>
)

export default Mission
