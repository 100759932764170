import React, { MouseEvent, ReactElement } from 'react'
import styled from 'styled-components'
import { Wrapper, Image, H, P, Button } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'
import { screen, screenMax, screenMin } from '@farewill/ui/helpers/responsive'

import WrapperWithEdge from 'components/WrapperWithEdge'
import scrollToElement from 'lib/navigation/scrollToElement'

const JOBS_LIST_ANCHOR_ELEMENT_ID = 'job-list'

const StyledTextureLeft = styled(Image)`
  position: absolute;
  pointer-events: none;

  left: -110%;
  bottom: -5%;

  ${screen.s`
    left:  -70%;
    top: -110px;
  `};

  ${screen.m`
    left:  -1rem;
    bottom: 5%;
  `};

  ${screenMin.l`
    left: -1rem;
    top: 10%;
    bottom: auto;
  `};
`

const StyledTextureRight = styled(Image)`
  position: absolute;
  pointer-events: none;
  right: -25%;
  top: 0;

  ${screenMin.m`
    right: -15%;
  `};

  ${screenMin.l`
    top: 20%;
    right: 0;
  `}
`

const StyledOuterWrapper = styled(Wrapper)`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${screen.m`
    min-height: 50vh;
  `}

  ${screenMin.l`
    min-height: 70vh;
  `}
`

const StyledInnerWrapper = styled(Wrapper)`
  position: relative;

  ${screenMax.s`
    a {
      width: 100%;
      max-width: none;
    }
  `}
`

const Hero = (): ReactElement => {
  const handleScrollToElement = (e: MouseEvent) => {
    scrollToElement({
      event: e as MouseEvent<HTMLButtonElement>,
      elementId: JOBS_LIST_ANCHOR_ELEMENT_ID,
    })
  }

  return (
    <WrapperWithEdge direction="down" edgeBackgroundColor={COLOR.WHITE}>
      <StyledOuterWrapper>
        <StyledTextureLeft
          path="textures/sketchy-swoop-yellow-03_k3g7yy"
          width={200}
          widthFromL={250}
          loading="auto"
        />
        <StyledTextureRight
          path="textures/detail_new_yellow02"
          width={350}
          loading="auto"
        />

        <StyledInnerWrapper container centeredFromM>
          <Wrapper centered>
            <Image
              path="e_trim/illustrations/cropped/blob_sofa_cat_plant"
              width={250}
              widthFromL={460}
              stretch
            />
          </Wrapper>
          <H size="XL" as="h1" decorative>
            Work at Farewill
          </H>
          <P
            size="L"
            margin={[0, 'auto', 'S']}
            marginFromL={[0, 'auto', 'L']}
            maxWidth={550}
            color={COLOR.BLACK}
          >
            We empower people to navigate some of life’s hardest moments. If
            that sounds like a challenge you’re up for, we’d love to hear from
            you.
          </P>

          <Button.White
            wide
            href={`#${JOBS_LIST_ANCHOR_ELEMENT_ID}`}
            onClick={handleScrollToElement}
          >
            See current jobs
          </Button.White>
        </StyledInnerWrapper>
      </StyledOuterWrapper>
    </WrapperWithEdge>
  )
}

export default Hero
