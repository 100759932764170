export const BENEFITS = [
  {
    name: 'Excellent compensation',
    description: 'A competitive salary benchmarked across other startups',
  },
  {
    name: 'Share options',
    description: 'Own a little bit of Farewill and share in our success',
  },
  {
    name: 'Hybrid working',
    description:
      'The option to work from home, with two days a week in the office with your team',
  },
  {
    name: 'A great working environment',
    description: 'A bright, modern office with coffee, fruit and snacks on tap',
  },
  {
    name: 'Friends and family discounts',
    description:
      '50% off wills and 20% off probate and direct cremations, for friends and family',
  },
  {
    name: 'Personal development',
    description: '£1,000 and 3 days a year to spend on your development',
  },
  {
    name: 'Time to recharge',
    description:
      '28 days holiday + bank holidays and flexible working, dependent on your role',
  },
  {
    name: 'Team socials',
    description:
      'Regular offsites and team lunches to get to know your collegues',
  },
  {
    name: 'Cycle to work scheme',
    description:
      'Save money in tax and pay in installments when you buy a new bike',
  },
  {
    name: 'Well-being line',
    description: 'Mental health support from trained counsellors',
  },
]
